import * as benefits from '../../../../utils/constants/benefits'

import * as kinds from '../../../../utils/constants/userTimelineKinds.js'

export default {
  timelineIsEmpty: 'aucun évènement',
  benefitsTitle: 'bénéfices',
  noBenefits: 'aucun bénéfice',

  counts: {
    [kinds.KIND_BAN_START]:
      'aucune suspension de compte | {count} suspension de compte | {count} suspensions de compte',
    [kinds.KIND_BAN_END]:
      'aucune suspension de compte terminée | {count} suspension de compte terminée | {count} suspensions de compte terminées',
    [kinds.KIND_CARD_RENEWAL_APPROVAL]:
      'aucun renouvellement de carte accepté | {count} renouvellement de carte accepté | {count} renouvellements de carte acceptés',
    [kinds.KIND_CARD_RENEWAL_PROPOSAL]:
      'aucun renouvellement de carte proposé | {count} renouvellement de carte proposé | {count} renouvellements de carte proposés',
    [kinds.KIND_CARD_RENEWAL_REJECTION]:
      'aucun renouvellement de carte rejeté | {count} renouvellement de carte rejeté | {count} renouvellements de carte rejetés',
    [kinds.KIND_DECLARATION]:
      'aucune déclaration | une déclaration | {count} déclarations',
    [kinds.KIND_EARNED_COUPON]:
      "aucun bon d'achat reçu | un bon d'achat reçu | {count} bons d'achat reçus",
    [kinds.KIND_LOYALTY_REWARD_USAGE]:
      'aucun cadeau demandé (hors remboursements) | un cadeau demandé (hors remboursements) | {count} cadeaux demandés (hors remboursements)',
    [kinds.KIND_OPTIN]:
      'aucune inscription | une inscription | {count} inscriptions',
    [kinds.KIND_OPTOUT]:
      'aucune carte perdue | une carte perdue | {count} cartes perdues',
    [kinds.KIND_ZENDESK]:
      'aucune réclamation (Zendesk) | une réclamation (Zendesk) | {count} réclamations (Zendesk)'
  },

  tooltips: {
    icons: {
      [kinds.KIND_BAN_START]: 'suspension(s) de compte',
      [kinds.KIND_BAN_END]: 'suspension(s) de compte teminée(s)',
      [kinds.KIND_CARD_RENEWAL_APPROVAL]:
        'renouvellement(s) de carte accepté(s)',
      [kinds.KIND_CARD_RENEWAL_PROPOSAL]:
        'proposition(s) de renouvellement(s) de carte',
      [kinds.KIND_CARD_RENEWAL_REJECTION]:
        'renouvellement(s) de carte rejeté(s)',
      [kinds.KIND_DECLARATION]: 'déclaration(s)',
      [kinds.KIND_EARNED_COUPON]: "bon(s) d'achat reçu(s)",
      [kinds.KIND_LOYALTY_REWARD_USAGE]:
        'cadeau(x) demandé(s) (hors remboursements)',
      [kinds.KIND_OPTIN]: 'carte(s) souscrite(s)',
      [kinds.KIND_OPTOUT]: 'carte(s) perdue(s)',
      [kinds.KIND_ZENDESK]: 'réclamation(s) (Zendesk)'
    }
  },

  benefits: {
    kinds: {
      [benefits.KIND_FIZ]:
        'aucun Fiz gagné | {count} Fiz gagné | {count} Fiz gagnés',
      [benefits.KIND_FIDZ]:
        'aucun Fidz gagné | {count} Fidz gagné | {count} Fidz gagnés',
      [benefits.KIND_POINTS]:
        'aucun point gagné | {count} point gagné | {count} points gagnés',
      [benefits.KIND_STAMPS]:
        'aucun tampon gagné | {count} tampon gagné | {count} tampons gagnés',
      [benefits.KIND_PUZZLE_PIECES]:
        'aucune pièce obtenue | {count} pièce obtenue | {count} pièces obtenues',
      [benefits.KIND_REFUND]: 'remboursé | remboursé | remboursés',
      [benefits.KIND_COUPONS]:
        'aucun bon utilisé | {count} bon utilisé | {count} bons utilisés',
      [benefits.KIND_COLLECTOR_PIECE]:
        'aucune pièce obtenue | {count} pièce obtenue | {count} pièces obtenues',
      [benefits.KIND_COLLECTOR_JOKER]:
        'aucun joker obtenu | {count}% de joker obtenu | {count}% de joker obtenus',
      [benefits.KIND_COLLECTOR_JOKER]:
        'aucun joker obtenu | {count}% de joker obtenu | {count}% de joker obtenus',
      [benefits.KIND_COLLECTOR_JOKER]:
        'aucun joker obtenu | {count}% de joker obtenu | {count}% de joker obtenus'
    },
    subkinds: {
      [benefits.SUBKIND_FOR_DECLARATION]: '(pour Fidz à gogo)',
      [benefits.SUBKIND_FOR_SCANS]: '(pour scans)'
    }
  },

  actions: {
    details: 'détails'
  },

  ban: {
    title: {
      [kinds.KIND_BAN_START]: 'compte suspendu',
      [kinds.KIND_BAN_END]: 'fin de la suspension de compte'
    },
    period: 'du {start} au {end}',
    since: 'à partir du {date}'
  },

  loyaltyRewardUsages: {
    status: 'statut',
    kindOfGift: 'type de cadeau',
    paidWithFidz: 'demandé avec des Fidz',
    paidWithPoints: 'demandé avec des points'
  },

  zendesk: {
    differentUserEmail: 'adresse de réclamation différente du compte',
    differentUserEmailAccount: 'adresse correspondant à un autre compte',
    linkToDeclaration: 'ouvrir la déclaration',
    linkToZendesk: 'ouvrir sur Zendesk'
  }
}
