import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.value,"scrollable":"","max-width":"500px"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c(VCard,[_c(VCardTitle,[_vm._v("\n        "+_vm._s(_vm.$t(("crud.offers.edit.products." + _vm.action + ".title")))+"\n        "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"href":("https://fr.openfoodfacts.org/produit/" + _vm.ean),"target":"_blank"}},[_vm._v("\n          OpenFoodFacts\n          "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-open-in-new")])],1)],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardText,{staticClass:"pt-3",staticStyle:{"height":"300px"}},[_c(VTextField,{attrs:{"disabled":"","label":_vm.$t('crud.offers.edit.products.update.fields.ean.label'),"value":_vm.ean,"outlined":""}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"productName","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('crud.offers.edit.products.update.fields.name.label'),"error-messages":errors,"outlined":""},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"productNameShort","mode":"eager","rules":"required|max:14","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"counter":14,"label":_vm.$t('crud.offers.edit.products.update.fields.nameShort.label'),"hint":_vm.$t('crud.offers.edit.products.update.fields.nameShort.hint'),"error-messages":errors,"outlined":""},model:{value:(_vm.fields.nameShort),callback:function ($$v) {_vm.$set(_vm.fields, "nameShort", $$v)},expression:"fields.nameShort"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"productBrand","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectBrand',{attrs:{"initial-brand":_vm.product &&
                _vm.product.brand_identifier && {
                  identifier: _vm.product.brand_identifier,
                  name: _vm.product.brand_name
                },"error-messages":errors},model:{value:(_vm.fields.brandIdentifier),callback:function ($$v) {_vm.$set(_vm.fields, "brandIdentifier", $$v)},expression:"fields.brandIdentifier"}})]}}],null,true)}),_vm._v(" "),_c(VContainer,[_c(VRow,[_c(VCol,[_c('h3',{staticClass:"mb-2"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    ("crud.offers.edit.products.update.illustration." + (_vm.product && _vm.product.image_url ? 'current' : 'noCurrent'))
                  ))+"\n              ")]),_vm._v(" "),(_vm.product && _vm.product.image_url)?_c(VSheet,{staticClass:"mb-3",attrs:{"outlined":"","tile":"","height":"200","width":"200"}},[_c(VImg,{attrs:{"src":_vm.product.image_url}})],1):_vm._e()],1),_vm._v(" "),_c(VCol,[_c('h3',{staticClass:"mb-2"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    ("crud.offers.edit.products.update.illustration." + (_vm.product && _vm.product.image_url ? 'change' : 'add'))
                  ))+"\n              ")]),_vm._v(" "),_c(VRadioGroup,{staticClass:"mt-0",attrs:{"column":"","mandatory":!_vm.product},on:{"change":_vm.resetImage},model:{value:(_vm.imageKind),callback:function ($$v) {_vm.imageKind=$$v},expression:"imageKind"}},[_c(VRadio,{attrs:{"label":_vm.$t(
                      'crud.offers.edit.products.update.illustration.with.file'
                    ),"value":"file"}}),_vm._v(" "),_c(VRadio,{attrs:{"label":_vm.$t(
                      'crud.offers.edit.products.update.illustration.with.remoteUrl'
                    ),"value":"remote"}})],1),_vm._v(" "),(_vm.imageKind === 'file')?_c('ValidationProvider',{attrs:{"name":"productImageFile","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c(VFileInput,{attrs:{"accept":"image/*","clearable":"","error-messages":errors},model:{value:(_vm.illustration.file),callback:function ($$v) {_vm.$set(_vm.illustration, "file", $$v)},expression:"illustration.file"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.imageKind === 'remote')?_c('ValidationProvider',{attrs:{"name":"productImageUrl","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c(VTextField,{attrs:{"prepend-icon":"mdi-open-in-new","clearable":"","error-messages":errors},model:{value:(_vm.illustration.remoteUrl),callback:function ($$v) {_vm.$set(_vm.illustration, "remoteUrl", $$v)},expression:"illustration.remoteUrl"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.newImageUrl)?_c(VSheet,{attrs:{"outlined":"","tile":"","height":"200","width":"200"}},[_c(VImg,{attrs:{"src":_vm.newImageUrl}})],1):_vm._e()],1)],1)],1)],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{on:{"click":_vm.close}},[_vm._v("\n          "+_vm._s(_vm.$t('global.cancel'))+"\n        ")]),_vm._v(" "),_c(VBtn,{attrs:{"loading":_vm.isSaving,"color":"blue darken-1 white--text"},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_vm._v("\n          "+_vm._s(_vm.$t('global.validate'))+"\n        ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }