import Vue from 'vue'
import { cloneDeep } from 'lodash-es'

import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

function splitNameAndEmail(email) {
  const match = email.match(/^(.+) <(.+)>$/)
  if (!match) return [null, email]

  return [match[1], match[2]]
}

export function initWithRecord(state, record) {
  state.original = record
  state.modified = cloneDeep(record)

  if (state.modified.from) {
    const [name, email] = splitNameAndEmail(state.modified.from)
    state.modified.fromEmail = email
    state.modified.fromName = name
  } else {
    state.modified.fromEmail = null
    state.modified.fromName = null
  }

  if (state.modified.reply_to) {
    const [name, email] = splitNameAndEmail(state.modified.reply_to)
    state.modified.replyToEmail = email
    state.modified.replyToName = name
  } else {
    state.modified.replyToEmail = null
    state.modified.replyToName = null
  }

  state.isLoaded = true
}

export function duplicateRecord(state, record) {
  const duplicate = { ...record, id: null }

  state.original = duplicate
  state.modified = cloneDeep(duplicate)
  state.isLoaded = true
}

export function updateConfig(state, config) {
  state.config = config
}

export function updateAttribute(state, { key, value }) {
  Vue.set(state.modified, key, value)
}
export function updateAttributeAuthor(state, { type, id }) {
  Vue.set(state.modified, 'author_type', type)
  Vue.set(state.modified, 'author_id', id)
}
export function updateAttributeAction(state, { type, id }) {
  Vue.set(state.modified, 'object_for_action_type', type)
  Vue.set(state.modified, 'object_for_action_id', id)
}
