const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticatedHasRights'] = require('../middleware/authenticatedHasRights.js')
middleware['authenticatedHasRights'] = middleware['authenticatedHasRights'].default || middleware['authenticatedHasRights']

middleware['authenticatedPurcheaseAdmin'] = require('../middleware/authenticatedPurcheaseAdmin.js')
middleware['authenticatedPurcheaseAdmin'] = middleware['authenticatedPurcheaseAdmin'].default || middleware['authenticatedPurcheaseAdmin']

middleware['not-authenticated'] = require('../middleware/not-authenticated.js')
middleware['not-authenticated'] = middleware['not-authenticated'].default || middleware['not-authenticated']

middleware['programReader'] = require('../middleware/programReader.js')
middleware['programReader'] = middleware['programReader'].default || middleware['programReader']

middleware['programSourceOfThruthIsAnalytics'] = require('../middleware/programSourceOfThruthIsAnalytics.js')
middleware['programSourceOfThruthIsAnalytics'] = middleware['programSourceOfThruthIsAnalytics'].default || middleware['programSourceOfThruthIsAnalytics']

middleware['programSourceOfThruthIsCore'] = require('../middleware/programSourceOfThruthIsCore.js')
middleware['programSourceOfThruthIsCore'] = middleware['programSourceOfThruthIsCore'].default || middleware['programSourceOfThruthIsCore']

middleware['programWithPoints'] = require('../middleware/programWithPoints.js')
middleware['programWithPoints'] = middleware['programWithPoints'].default || middleware['programWithPoints']

middleware['programWithPuzzle'] = require('../middleware/programWithPuzzle.js')
middleware['programWithPuzzle'] = middleware['programWithPuzzle'].default || middleware['programWithPuzzle']

middleware['programWithStamps'] = require('../middleware/programWithStamps.js')
middleware['programWithStamps'] = middleware['programWithStamps'].default || middleware['programWithStamps']

middleware['redirectChildrenProgramsToSettings'] = require('../middleware/redirectChildrenProgramsToSettings.js')
middleware['redirectChildrenProgramsToSettings'] = middleware['redirectChildrenProgramsToSettings'].default || middleware['redirectChildrenProgramsToSettings']

middleware['redirectHome'] = require('../middleware/redirectHome.js')
middleware['redirectHome'] = middleware['redirectHome'].default || middleware['redirectHome']

middleware['redirectHomePointsOfSale'] = require('../middleware/redirectHomePointsOfSale.js')
middleware['redirectHomePointsOfSale'] = middleware['redirectHomePointsOfSale'].default || middleware['redirectHomePointsOfSale']

middleware['redirectHomeProgram'] = require('../middleware/redirectHomeProgram.js')
middleware['redirectHomeProgram'] = middleware['redirectHomeProgram'].default || middleware['redirectHomeProgram']

middleware['redirectHomeUser'] = require('../middleware/redirectHomeUser.js')
middleware['redirectHomeUser'] = middleware['redirectHomeUser'].default || middleware['redirectHomeUser']

middleware['redirectHomeUserApp'] = require('../middleware/redirectHomeUserApp.js')
middleware['redirectHomeUserApp'] = middleware['redirectHomeUserApp'].default || middleware['redirectHomeUserApp']

export default middleware
