<template>
  <v-tabs align-with-title :background-color="paletteScanobar.black">
    <v-tab v-for="tab in tabs" :key="tab.identifier" nuxt :to="tab.to">
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import MixinPaletteScanobar from '@/mixins/paletteScanobar'

export const TAB_TREE = 'tree'
export const TAB_DENOMS = 'denoms'
export const TAB_DENOMS_V2 = 'denoms-v2'

export const TABS = [TAB_TREE, TAB_DENOMS, TAB_DENOMS_V2]

export const DEFAULT_TAB = TAB_TREE

export default {
  mixins: [MixinPaletteScanobar],
  computed: {
    tabs() {
      return TABS.map((tab) => ({
        identifier: tab,
        to: `/sources/products/${tab}`,
        title: this.$t(`layout.sources.products.tabs.${tab}`)
      }))
    }
  }
}
</script>
