import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VToolbar,{staticClass:"v-toolbar--mini-padding-top",attrs:{"extended":"","color":_vm.paletteScanobar.black,"dark":"","height":_vm.height},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{staticClass:"pa-0",staticStyle:{"min-width":"0px"}}),_vm._v(" "),_c(VTab,{attrs:{"nuxt":"","to":(_vm.routeBasePath + "/timeline")}},[_vm._v(_vm._s(_vm.$t('layout.user.tabs.timeline')))]),_vm._v(" "),_c(VTab,{attrs:{"nuxt":"","to":(_vm.routeBasePath + "/declarations")}},[_vm._v(_vm._s(_vm.$t('layout.user.tabs.declarations')))]),_vm._v(" "),_c(VTab,{attrs:{"nuxt":"","to":(_vm.routeBasePath + "/profile")}},[_vm._v(_vm._s(_vm.$t('layout.user.tabs.profile')))]),_vm._v(" "),(_vm.hasFidmarques)?_c(VTab,{attrs:{"nuxt":"","to":(_vm.routeBasePath + "/app")}},[_vm._v(_vm._s(_vm.$t('layout.user.tabs.app')))]):_vm._e()],1),_vm._v(" "),(!_vm.user)?_c(VSkeletonLoader,{attrs:{"type":"heading","width":"500"}}):(_vm.user.facebook_id)?_c('BarTitleWithFb',{attrs:{"user":_vm.user,"seniority":_vm.seniority}}):_vm._e(),_vm._v(" "),(_vm.user)?_c(VFabTransition,[_c(VBtn,{attrs:{"color":"primary","fab":"","dark":"","small":"","absolute":"","bottom":"","right":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1)],1):_vm._e()]},proxy:true}])},[_c(VToolbarTitle,[(_vm.error)?_c('DisplayErrorV2',{attrs:{"error":_vm.error,"retriable":""},on:{"retry":_vm.fetchUserFromRoute}}):(!_vm.user)?_c(VSkeletonLoader,{attrs:{"type":"heading","width":"500"}}):_c('BarTitle',{attrs:{"user":_vm.user,"seniority":_vm.seniority}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),(_vm.user)?_c(VRow,{staticClass:"header__fraud mt-9"},[(_vm.isVip)?_c(VCol,{staticClass:"pa-0",attrs:{"cols":_vm.hasCurrentBans ? '6' : '11'}},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-10"},'v-icon',attrs,false),on),[_vm._v("mdi-crown")])]}}],null,false,3659958932)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.user.notes))])])],1):(_vm.hasNotes)?_c(VCol,{staticClass:"pa-0",attrs:{"cols":_vm.hasCurrentBans ? '6' : '11'}},[_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-10"},'v-icon',attrs,false),on),[_vm._v("\n            mdi-message-bulleted\n          ")])]}}],null,false,140471506)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.user.notes))])])],1):_vm._e(),_vm._v(" "),(_vm.hasCurrentBans)?_c(VCol,{staticClass:"header__fraud__line-1 pb-0 pl-0 pt-0",attrs:{"cols":_vm.isVip || _vm.hasNotes ? '6' : ''}},[_c('BanChip',{staticClass:"ma-0",attrs:{"to":(_vm.routeBasePath + "/bans/" + (_vm.currentBans[0].id)),"ban":_vm.currentBans[0],"message":"bannedAt"}})],1):_vm._e(),_vm._v(" "),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[(_vm.hasOldBans)?_c(VMenu,{attrs:{"offset-y":"","content-class":"max-height-95vh"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v("\n            "+_vm._s(_vm.$tc('layout.user.header.oldBans', _vm.oldBansCount))+"\n          ")])]}}],null,false,173872167)},[_vm._v(" "),_vm._l((_vm.oldBansByEditorName),function(oldBans,editor){return _c(VList,{key:editor,attrs:{"subheader":""}},[(_vm.user.apps.length > 1)?_c(VSubheader,[_vm._v(_vm._s(editor))]):_vm._e(),_vm._v(" "),(_vm.user.apps.length > 1)?_c(VDivider):_vm._e(),_vm._v(" "),_vm._l((oldBans),function(oldBan){return _c(VListItem,{key:oldBan.id,attrs:{"nuxt":"","to":(_vm.routeBasePath + "/bans/" + (oldBan.id))}},[_c(VListItemIcon,{staticClass:"mr-0"},[_c(VSheet,{attrs:{"color":_vm.otherBanColor(oldBan),"elevation":"1","height":"10","rounded":"","width":"10"}})],1),_vm._v(" "),_c(VListItemTitle,{staticClass:"mb-2"},[_vm._v("\n              "+_vm._s(_vm.$t('layout.user.header.bannedAt', {
                  date: _vm.momentFormat(oldBan.start_at, 'date')
                }))+"\n            ")])],1)})],2)})],2):_vm._e()],1)],1):_vm._e(),_vm._v(" "),_vm._v(" "),(_vm.dialog)?_c(VDialog,{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.user && !_vm.error)?_c('ModalDetails',{on:{"close":function($event){_vm.dialog = false}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }